<template>
  <div class="container-fluid">
      <b-form class="row" @submit.prevent="save()">
      <div class="col-12">
        <h5>{{ match.user_a_nick }} vs {{ match.user_b_nick }}</h5>
        <hr>
      </div>
      <div class="form-group col-6">
        <label for="local_goals">Local - <strong>{{ match.user_a_nick }}</strong></label>
        <b-form-input type="number" name="local_goals" id="local_goals" v-model="match.local_goal" readonly=""></b-form-input>
        <img v-if="match.user_a_image" :src="match.user_a_image" alt="Resultado del local" class="img-fluid img-thumbnail mt-3">
      </div>
      <div class="form-group col-6">
        <label for="visit_goals">{{ $t('visitor') }} - <strong>{{ match.user_b_nick }}</strong></label>
        <b-form-input type="number" name="visit_goals" v-model="match.visit_goal" readonly=""></b-form-input>
        <img v-if="match.user_b_image" :src="match.user_b_image" alt="Resultado del visitante" class="img-fluid img-thumbnail mt-3">
      </div>
      <div class="col-12">
        <b-button type="submit" variant="primary">{{ $t('confirmResults') }}</b-button>
        <b-button :to="{ name: 'Bets', params: { lang: lang, console: console } }" class="ml-2">{{ $t('cancel') }}</b-button>
      </div>
    </b-form>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      match: {},
      matchId: this.$route.params.betId
    }
  },
  created () {
    this.fetchData()
  },
  computed: {
    ...mapGetters([
      'console',
      'lang'
    ])
  },
  methods: {
    fetchData () {
      const path = `auth/${this.lang}/console/${this.console}/shop/bets/vs/${this.matchId}/confirm-results`
      this.$axios.get(path).then(response => {
        this.match = response.data.data
      })
    },
    save () {
      const path = `auth/${this.lang}/console/${this.console}/shop/bets/vs/${this.matchId}/confirm-results`
      this.$axios.post(path, this.match).then((response) => {
        this.$toastr.success(response.data.message, window.TITLE_SUCCESS)
        this.$router.push({ name: 'Vs', params: { lang: this.lang, console: this.console } })
      })
    }
  }
}
</script>
